// submission mutation types
export const SET_IS_GEOCODING = "setIsGeocoding";
export const SET_GEOCODE_OPTIONS = "setGeocodeOptions";
export const SET_VALIDATION_PROCESSING_ID = "setValidationProcessingId";
export const OPEN_DIALOG = "openDialog";
export const CLOSE_DIALOG = "closeDialog";
export const SET_OFFER_PROCESSING = "setOfferProcessing";
export const REMOVE_OFFER = "removeOffer";
export const ADD_OFFER = "addOffer";
export const ADD_CREATING_OFFER_ID = "addCreatingOfferId";
export const REMOVE_CREATING_OFFER_ID = "removeCreatingOfferId";

export const SET_IS_PROCESSING = "setIsProcessing";

export const SET_IS_FLAG_AS_SPAM_PROCESSING = "setIsFlaggingAsSpamProcessing";
export const SET_IS_ADDRESS_PROCESSING = "setIsAddressProcessing";
export const SET_IS_ADDRESS_EDIT_PROCESSING = "setIsAddressEditProcessing";
export const SET_IS_ADDRESS_RESET_PROCESSING = "setIsAddressResetProcessing";
export const SET_IS_SUBJECT_PROCESSING = "setIsSubjectProcessing";
