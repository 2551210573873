import { isNil } from "lodash";
import { SET_ENTITIES, SET_IS_LOADING, SET_UNREGISTER } from "./mutation-types";
import { setEntities } from "../../helpers/entity";

export default {
  [SET_ENTITIES](state, { entities, id = null }) {
    if (!isNil(id)) {
      state.id = id;
    }
    setEntities(state, entities);
  },
  [SET_IS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_UNREGISTER](state, unregister) {
    state.unregister = unregister;
  }
};
