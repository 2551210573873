import Vue from "vue";
import { isArray, isNil, mergeWith } from "lodash";

export const createEntityPlaceholders = entityNames => {
  const entities = { entity: {} };
  if (!isArray(entityNames)) {
    entityNames = [entityNames];
  }
  entityNames.forEach(entityName => {
    entities[entityName] = {};
  });
  return { entities };
};

export const createSelectEntityPlaceholders = entityNames => {
  const entities = { entity: {} };
  if (!isArray(entityNames)) {
    entityNames = [entityNames];
  }
  entityNames.forEach(entityName => {
    entities[entityName] = {};
  });
  return { entities };
};

export function setEntities(state, entities) {
  // Loop over the object containing all the entity maps.
  Object.entries(entities).forEach(([entityName, entityMap]) => {
    // If entity map does not exist yet in state.entities, create it.
    if (isNil(state.entities[entityName])) {
      Vue.set(state.entities, entityName, {});
    }
    // Loop over the entity map
    Object.entries(entityMap).forEach(([entityId, entity]) => {
      // Create the updated entity by taking the old entity (or an empty object if there is none) and merging the new entity into it.
      const updatedEntity = mergeWith(
        state.entities[entityName][entityId] || {},
        entity,
        mergeCopyArrays
      );
      // Set the entity using Vue.set to avoid reactivity issues.
      Vue.set(state.entities[entityName], entityId, updatedEntity);
    });
  });
}

/**
 * Returns srcValue when objValue is an array.
 * Returns undefined otherwise.
 *
 * @param objValue
 * @param srcValue
 * @returns srcValue|undefined
 */
function mergeCopyArrays(objValue, srcValue) {
  if (isArray(objValue)) {
    return srcValue;
  }
}
