import Vue from "vue";
import { isNil } from "lodash";
import mutations from "@/store/templates/detail/mutations";
import {
  ADD_OFFER,
  CLOSE_DIALOG,
  OPEN_DIALOG,
  SET_GEOCODE_OPTIONS,
  SET_IS_ADDRESS_EDIT_PROCESSING,
  SET_IS_ADDRESS_RESET_PROCESSING,
  SET_IS_FLAG_AS_SPAM_PROCESSING,
  SET_IS_GEOCODING,
  SET_IS_PROCESSING,
  SET_IS_SUBJECT_PROCESSING,
  SET_OFFER_PROCESSING,
  SET_VALIDATION_PROCESSING_ID
} from "@/store/modules/submission/mutation-types";
import { REMOVE_OFFER } from "./mutation-types";

export default {
  ...mutations,
  [OPEN_DIALOG](state, { type, props = {} }) {
    if (isNil(props.width)) {
      props.width = "500";
    }
    Object.assign(state.dialog, { type, props });
  },
  [CLOSE_DIALOG](state) {
    Vue.set(state, "dialog", { type: null, props: {} });
  },
  setSubjectAndFieldsShown,
  addLead,
  removeLead,
  [SET_IS_GEOCODING](state, isGeocoding) {
    state.isGeocoding = isGeocoding;
  },
  [SET_GEOCODE_OPTIONS](state, geocodeOptions) {
    state.geocodeOptions = geocodeOptions;
    state.isGeocoding = false;
  },
  [SET_VALIDATION_PROCESSING_ID](state, validationRuleId) {
    state.validationProcessingId = validationRuleId;
  },
  [SET_OFFER_PROCESSING](state, { offerId, isProcessing }) {
    const index = state.offersProcessing.indexOf(offerId);
    if (isProcessing && index === -1) {
      state.offersProcessing.push(offerId);
    } else if (!isProcessing && index !== -1) {
      state.offersProcessing.splice(index, 1);
    }
  },
  [REMOVE_OFFER](state, { leadId, offerId }) {
    const lead = state.entities.lead[leadId];
    lead.offers.splice(lead.offers.indexOf(offerId), 1);
    state.entities.offer[offerId] = undefined;
    delete state.entities.offer[offerId];
    lead.currentOfferCount = lead.currentOfferCount - 1;
  },
  [ADD_OFFER](state, { leadId, offerId }) {
    const lead = state.entities.lead[leadId];
    lead.offers.push(offerId);
  },
  [SET_IS_PROCESSING](state, isProcessing) {
    state.isProcessing = isProcessing;
  },
  [SET_IS_FLAG_AS_SPAM_PROCESSING](state, isFlagAsSpamProcessing) {
    state.processing.flagAsSpam = isFlagAsSpamProcessing;
  },
  [SET_IS_ADDRESS_EDIT_PROCESSING](state, isAddressEditProcessing) {
    state.processing.addressEdit = isAddressEditProcessing;
  },
  [SET_IS_ADDRESS_RESET_PROCESSING](state, isAddressReset) {
    state.processing.addressReset = isAddressReset;
  },
  [SET_IS_SUBJECT_PROCESSING](state, isSubjectProcessing) {
    state.processing.subject = isSubjectProcessing;
  },
  addAutoclaimingLead(state, leadId) {
    state.autoclaimingLeads.push(leadId);
  },
  removeAutoclaimingLead(state, leadId) {
    state.autoclaimingLeads.splice(state.autoclaimingLeads.indexOf(leadId), 1);
  }
};

function setSubjectAndFieldsShown(state, isSubjectAndFieldsShown) {
  state.isSubjectAndFieldsShown = isSubjectAndFieldsShown;
}

function addLead(state, leadId) {
  const submission = state.entities.submission[state.id];
  submission.leads.push(leadId);
}

function removeLead(state, leadId) {
  const submission = state.entities.submission[state.id];
  submission.leads.splice(submission.leads.indexOf(leadId), 1);
  Vue.delete(state.entities.lead, leadId);
}
